<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="topsubmenu auto">
          <a-radio-group default-value="1" size="small" @change="changeSubmenu" button-style="solid">
            <a-radio-button value="1">
              人员授权
            </a-radio-button>
            <a-radio-button value="2">
              角色管理
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="content-title auto">
          <div class="coustom-title fl">授权人员（{{total}}人）</div>
        </div>
          
        <div class="coustom-title-mes auto">
          <a-icon type="info-circle" theme="filled" style="color:#226FBB;margin-right: 10px;" />已授权人员 {{total}} 人
        </div>
        <div class="content-bar auto">
          <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <!-- <a-button type="primary" size="small" class="m-r-5 fl" @click="addUser"> 添加管理员 </a-button> -->
              <a-input type="text" v-model="filter.param" size="small" placeholder="请输入学工号/姓名" class="m-r-5 fl" style="width:160px;" allowClear></a-input>
              <a-button type="primary" size="small" class="fl" @click="searchHandle">查找</a-button>
            </div>
          </custom-page>
        </div>
        <div class="content-table auto">
          <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
            <div slot="department" slot-scope="text">
              <div v-if="departmentNameDone">
                <span v-for="(item, index) in text" :key="index">{{departmentName[item]}}<span v-show="index !== text.length - 1">，</span></span>
              </div>
            </div>
            <div slot="roles" slot-scope="text">
              <span v-for="(item1, index) in text" :key="index">{{filters['roles'] && filters['roles'].find((item) => { return item1 == item.value}) && filters['roles'].find((item) => { return item1 == item.value})['label']}}<span v-show="index !== text.length - 1">，</span></span>
            </div>
            <div slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" class="m-r-5" @click="getAuthorizationUserRoles(record)">编辑</a-button>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="deleteUser(record)"
              >
                <a-button type="primary" size="small" class="bgred borred">删除</a-button>
              </a-popconfirm>
            </div>
          </a-table>
        </div>
        <div class="content-bar auto">
          <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <!-- <a-button type="primary" size="small" class="m-r-5 fl" @click="addUser"> 添加管理员 </a-button> -->
              <a-input type="text" v-model="filter.param" size="small" placeholder="请输入学工号/姓名" class="m-r-5 fl" style="width:160px;" allowClear></a-input>
              <a-button type="primary" size="small" class="fl" @click="searchHandle">查找</a-button>
            </div>
          </custom-page>
        </div>
      </a-spin>
      <a-drawer
        width="100%"
        title=""
        placement="right"
        :closable="false"
        :visible="visible"
        :get-container="false"
        :wrap-style="{ position: 'absolute' }"
      >
        <div class="drawer-bar auto">
          <a-button icon="double-left" size="small" class="m-r-10" @click="onClose">返回</a-button>
        </div>
        <div class="content-title auto">
          <div class="coustom-title fl">添加管理员</div>
          <div class="coustom-title-mes fr">
            <a-icon type="info-circle" theme="filled" style="color:#1890FF;margin-right: 10px;" />为您检索到 {{total2}} 人
          </div>
        </div>
        <div class="content-bar auto">
          <custom-page :total="total2" @getPageMes="getPageMes2" :page="filter2.current_page" :limit="filter2.page_size">
            <div slot="custom">
              <a-input type="text" v-model="filter2.param" size="small" placeholder="请输入学工号/姓名" class="m-r-5 fl" allowClear style="width:160px;"></a-input>
              <a-button type="primary" size="small" class="fl" @click="searchHandle2">查找</a-button>
            </div>
          </custom-page>
        </div>
        <div class="content-table auto">
          <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns2" :data-source="tabledata2" size="middle" :pagination="false">
            <div slot="department" slot-scope="text">
              <span v-for="(item, index) in text" :key="index">{{departmentName[item]}}<span v-show="index !== text.length - 1">，</span></span>
            </div>
            <div slot="roles" slot-scope="text">
              <span v-for="(item1, index) in text" :key="index">{{filters['roles'] && filters['roles'].find((item) => { return item1 == item.value}) && filters['roles'].find((item) => { return item1 == item.value})['label']}}<span v-show="index !== text.length - 1">，</span></span>
            </div>
            <div slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" class="m-r-5 bgyellow boryellow" @click="addRoles(record)" v-if="!record.roles.length">添加</a-button>
              <a-button type="primary" size="small" class="m-r-5" @click="getAuthorizationUserRoles(record)" v-if="record.roles.length">编辑</a-button>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="deleteUser(record)"
              >
                <a-button type="primary" size="small" class="bgred borred" v-if="record.roles.length">删除</a-button>
              </a-popconfirm>
            </div>
          </a-table>
        </div>
      </a-drawer>
    </a-layout-content>

    <a-modal v-model="detailVisible" :title="'人员授权编辑（' + detail_row.schoolid + '，' + detail_row.name + '）'" :footer="null" @cancel="detailClose" @ok="detailClose" width="1000px">
      <div>
        <a-button type="primary" size="small" @click="EditRoles(detail_row)">添加角色</a-button>
      </div>
      <div class="log-table m-t-10">
        <a-table :loading="detailTableLoading" :rowKey="(record,index)=>{return index}" :columns="detailTableHeader" :data-source="detailTotalData" :pagination="false">
          <div slot="grantor_schoolid" slot-scope="text, record">
            {{text ? text : '——'}}
          </div>
          <div slot="grantor_name" slot-scope="text, record">
            {{text ? text : '——'}}
          </div>
          <div slot="decentralization" slot-scope="text, record">
            <div v-if="text === true || text === false">
              <a-switch v-model="record.decentralization" size="small" @change="changeDecentralization($event, record)" />
            </div>
          </div>
          <div slot="action" slot-scope="text, record">
            <a-popconfirm
              title="确定要删除吗?"
              ok-text="确定"
              cancel-text="取消"
              placement="topRight"
              @confirm="deleteRole(record)"
            >
              <a-button type="danger" size="small" class="bgred borred">删除</a-button>
            </a-popconfirm>
          </div>
        </a-table>
      </div>
      <div class="log-page">
        <custom-page :total="count3" @getPageMes="getPageMes3" :page="detailFilter.current_page" :limit="detailFilter.page_size"></custom-page>
      </div>
    </a-modal>
    <a-modal v-model="addVisible" title="添加角色" @cancel="addCancel" @ok="addSubmit" width="900px">
      <!-- <a-row>
        <a-col :span="24">
          {{detail_row.schoolid}}，{{detail_row.name}}，<span v-for="(item, index) in detail_row.department" :key="index">{{departmentName[item]}}<span v-show="index !== detail_row.department.length - 1">，</span></span>
        </a-col>
      </a-row> -->
      <a-row>
      <!-- <a-row class="m-t-10"> -->
        <a-col :span="24">
          <!-- <a-transfer
            v-if="filters['roles']"
            :data-source="filters['roles']"
            :titles="['可选授权标签', '已选授权标签']"
            :target-keys="current_roles"
            @change="handleTagsChange"
            :render="item => item.title"
          /> -->
          <div class="current_roles_box">
            <a-checkbox-group
              v-model="current_roles"
              style="width: 100%;"
            >
              <!-- <span slot="label" slot-scope="{ value }" style="color: red" class="ellipsis roles_item">1{{ value }}</span> -->
              <a-row>
                <a-col :span="8" v-for="(item, index) in rolesList" :key="index">
                  <a-checkbox :value="item.value" :disabled="item.disabled">
                    <span class="ellipsis roles_item" :title="item.label">{{item.label}}</span>
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      loading: true,
      filters: {},
      departmentName: {},
      departmentNameDone: false,
      tablecolumns: [
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '所属单位',
          dataIndex: 'department',
          scopedSlots: { customRender: 'department' }
        },
        {
          title: '授权角色',
          dataIndex: 'roles',
          scopedSlots: { customRender: 'roles' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          customCell: () => {
            return {
              style: {
                'width': '150px',
              }
            }
          }
        }
      ],
      tabledata: [],
      visible: false,
      addVisible: false,
      tablecolumns2: [
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '所属单位',
          dataIndex: 'department',
          scopedSlots: { customRender: 'department' }
        },
        {
          title: '授权角色',
          dataIndex: 'roles',
          scopedSlots: { customRender: 'roles' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          customCell: () => {
            return {
              style: {
                'width': '150px',
              }
            }
          }
        }
      ],
      tabledata2: [],
      filter2: {
        type: 'all',
        param: '',
        current_page: 1,
        page_size: 10
      },
      total2: 0,
      mockData: [],
      targetKeys: [],
      filter: {
        type: 'own',
        param: '',
        current_page: 1,
        page_size: 10
      },
      total: 0,
      current_row: {},
      current_roles: [],
      detailVisible: false,
      detailTableLoading: false,
      detailTableHeader: [
        {
          title: '授权角色',
          dataIndex: 'rolename'
        },
        {
          title: '授权人学工号',
          dataIndex: 'grantor_schoolid',
          scopedSlots: { customRender: 'grantor_schoolid' }
        },
        {
          title: '授权人',
          dataIndex: 'grantor_name',
          scopedSlots: { customRender: 'grantor_name' }
        },
        {
          title: '二级授权',
          dataIndex: 'decentralization',
          scopedSlots: { customRender: 'decentralization' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          customCell: () => {
            return {
              style: {
                'width': '150px',
              }
            }
          }
        }
      ],
      detailTableData: [],
      detailTotalData: [],
      count3: 0,
      detailFilter: {
        current_page: 1,
        page_size: 10
      },
      detail_row: {},
      rolesList: []
    }
  },
  methods: {
    changeSubmenu (e) {
      var value = Number(e.target.value)
      console.log('value', value)
      switch (value) {
        case 1:
          this.$router.push('/system/power/index');
          break;
        case 2:
          this.$router.push('/system/power/roles');
          break;
        default:
          break;
      }
    },
    async deleteRole (row) {
      let res = await System.deleteAuthorizationUserRoles(this.detail_row.userid, row.id)
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getAuthorizationUserRoles(this.detail_row)
        let index = this.detail_row.roles.findIndex(item => {
          return Number(item) === Number(row.roleid)
        })
        this.detail_row.roles.splice(index, 1)
        this.getAuthorizationUser()
        this.getAuthorizationUserAll()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async changeDecentralization (e, row) {
      let res = await System.changeAuthorizationUserRoles(this.detail_row.userid, row.id, e ? 'open' : 'close')
      if (!res['code']) {
        this.$message.success('修改成功！')
      } else {
        this.getAuthorizationUserRoles(this.detail_row)
        this.$message.error(this.errMsg[res['code']])
      }
    },
    getPageMes3 (obj) {
      this.detailFilter.current_page = obj.pageNumber
      this.detailFilter.page_size = obj.pageSize
      this.getDetailPageData()
      // this.getAuthorizationUserRoles(this.detail_row)
    },
    getDetailPageData () {
      let tableData = JSON.parse(JSON.stringify(this.detailTableData))
      this.detailTotalData = tableData.splice((this.detailFilter.current_page - 1) * this.detailFilter.page_size, this.detailFilter.current_page * this.detailFilter.page_size)
    },
    detailClose () {
      this.detailTableData = []
      this.detailTotalData = []
      this.detail_row = {}
      this.detailFilter = {
        current_page: 1,
        page_size: 10
      }
      this.detailVisible = false
    },
    async getAuthorizationUserRoles (row) {
      this.detail_row = row
      this.detailVisible = true
      this.detailTableLoading = true
      let res = await System.getAuthorizationUserRoles(row.userid, this.detailFilter)
      this.detailTableLoading = false
      if (!res['code']) {
        let table = []
        res['data'].map(item => {
          if (Number(item.decentralization) === 1) {
            item.decentralization = true
          } else if (Number(item.decentralization) === 0) {
            item.decentralization = false
          } else {
            item.decentralization = null
          }
          table.push(item)
        })
        this.detailTableData = table
        this.getDetailPageData()
        this.count3 = this.detailTableData.length
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    handleTagsChange (nextTargetKeys, direction, moveKeys) {
      this.current_roles = nextTargetKeys
    },
    addCancel () {
      this.current_row = {}
      this.current_roles = []
      this.addVisible = false
    },
    addRoles (row) {
      this.detail_row = row
      this.current_roles = row.roles
      this.addVisible = true
      this.rolesList = JSON.parse(JSON.stringify(this.filters['roles']))
    },
    EditRoles (row) {
      this.detail_row = row
      this.current_roles = row.roles
      this.addVisible = true
      let list = []
      this.filters['roles'].map(item => {
        let status = this.detailTableData.find(item2 => {
          return Number(item2.roleid) === Number(item.value)
        })
        if (status) {
          let newItem = JSON.parse(JSON.stringify(item))
          newItem['disabled'] = true
          list.push(newItem)
        } else {
          list.push(item)
        }
      })
      this.rolesList = list
    },
    async addSubmit () {
      let list = []
      this.current_roles.map(item => {
        let status = this.detailTableData.find(item2 => {
          return Number(item2.roleid) === Number(item)
        })
        if (!status) {
          list.push(item)
        }
      })
      let res = await System.changeAuthorizationUser(this.detail_row.userid, list)
      if (!res['code']) {
        this.$message.success('操作成功！')
        this.addCancel()
        this.getAuthorizationUser()
        this.getAuthorizationUserAll()
        if (this.detailVisible) {
          list.map(item => {
            this.detail_row.roles.push(item)
          })
          this.detail_row = JSON.parse(JSON.stringify(this.detail_row))
          this.getAuthorizationUserRoles(this.detail_row)
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    addUser () {
      this.visible = true
      // if (!this.tabledata2.length) {
      //   this.getAuthorizationUserAll()
      // }
    },
    onClose () {
      this.visible = false
      this.filter2.param = ''
      this.searchHandle2()
    },
    async deleteUser (row) {
      let res = await System.deleteAuthorizationUser(row.userid)
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getAuthorizationUser()
        this.getAuthorizationUserAll()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    searchHandle2 () {
      this.filter2.current_page = 1
      this.getAuthorizationUserAll()
    },
    getPageMes2 (obj) {
      this.filter2.current_page = obj.pageNumber
      this.filter2.page_size = obj.pageSize
      this.getAuthorizationUserAll()
    },
    async getAuthorizationUserAll () {
      let res = await System.getAuthorizationUser(this.filter2)
      if (!res['code']) {
        this.tabledata2 = res['data']
        this.total2 = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    searchHandle () {
      this.addUser()
      this.filter2.param = this.filter.param
      this.searchHandle2()
      // this.filter.current_page = 1
      // this.getAuthorizationUser()
    },
    getPageMes (obj) {
      this.filter.current_page = obj.pageNumber
      this.filter.page_size = obj.pageSize
      this.getAuthorizationUser()
    },
    async getAuthorizationUser () {
      this.loading = true
      let res = await System.getAuthorizationUser(this.filter)
      this.loading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.total = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    trunDepartmentData (data) {
      for (let k in data) {
        this.departmentName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunDepartmentData(data[k]['children'])
        }
      }
    },
    async getAuthorizationCode () {
      // let res = await System.getAuthorizationCode('role')
      let res = await System.getAuthorizationActiveRole()
      if (!res['code']) {
        // this.filters['roles'] = res['data']
        let rolesList = []
        res['data'].map(item => {
          rolesList.push({
            value: String(item.id),
            label: item.name
          })
        })
        this.filters['roles'] = rolesList
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
      let res2 = await System.getAuthorizationCode('department')
      if (!res2['code']) {
        this.filters['department'] = res2['data']
        this.trunDepartmentData(res2['data'])
        this.departmentNameDone = true
      } else {
        // this.$message.error(res2['description'])
        this.$message.error(this.errMsg[res2['code']])
      }
      this.getAuthorizationUser()
    }
  },
  created () {
    this.getAuthorizationCode()
  },
  mounted () {
  }
};
</script>

<style scoped>
.content-bar {
  padding: 8px 0;
}
.log-page {
  padding: 20px 0;
}

.roles_item {
  display: inline-block;
  width: 140px;
  /*height: 20px;*/
  line-height: 30px;
  margin-bottom: -10px;
  overflow: hidden;
}

.current_roles_box {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.topsubmenu {
  padding-bottom: 0;
}
.coustom-title {
  color: rgba(0,0,0,0.85);
}
</style>
